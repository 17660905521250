import { RouteRecordRaw } from 'vue-router';
import { LoginVerificationType } from '../model';
import { RouteKey } from '../routeKey';

export const TabRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('@/layout/index.vue'),
        redirect: '/index',
        // 需要layout的页面
        children: [
            {
                path: 'index',
                name: RouteKey.Index,
                meta: {
                    title: '首页',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/index.vue')
            }
        ]
    }
];
