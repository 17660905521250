import { CSSProperties } from 'vue';

// 验证方式，必须登录，还是必须不登录，还是不验证
export enum LoginVerificationType {
    // 必须登录
    MUST_LOGIN = 'MUST_LOGIN',
    // 必须不登录
    MUST_NOT_LOGIN = 'MUST_NOT_LOGIN',
    // 不验证
    NO_VERIFICATION = 'NO_VERIFICATION'
}
export interface RouteMetaModel {
    title?: string;
    // 登录的验证方式
    loginVerificationType?: LoginVerificationType;
    // 页面样式
    style?: CSSProperties;
}
