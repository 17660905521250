import { showDialog, showConfirmDialog, version } from 'vant';
import { useLang } from './useLang';

export interface DialogTextConfig {
    title?: string;
    message?: string;
}

export interface DialogConfirmConfig {
    title?: string;
    message?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
}

export function useDialog() {
    // let toast: any = null;

    const text = (param: DialogTextConfig) => {
        return new Promise(async (resolve, reject) => {
            try {
                const l = useLang().l;
                await showDialog({
                    title: param?.title || l('提示'),
                    message: param?.message || ''
                });
                resolve(true);
            } catch (error) {
                reject(error);
            }
        });
    };
    const confirm = async (param: DialogConfirmConfig): Promise<boolean> => {
        return new Promise(async (resolve) => {
            const l = useLang().l;
            try {
                await showConfirmDialog({
                    title: param?.title || l('提示'),
                    message: param?.message || '',
                    confirmButtonText: param?.confirmButtonText || l('确认'),
                    cancelButtonText: param?.cancelButtonText || l('取消')
                });
                resolve(true);
            } catch (error) {
                resolve(false);
                console.log(error);
            }
        });
    };

    // onBeforeUnmount(stopLoading);

    return { text, confirm };
}
