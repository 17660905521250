import {
    useTheme,
    useLang,
    useAijobJsSdk,
    useClient,
    useGlobal,
    useAuth,
    useUser,
    useUserPermission,
    useCity,
    useDepartment,
    useLoading,
    useDialog
} from '@/hooks';
import { useCompany } from './useCompany';
import { LoginVerificationType, RouteMetaModel } from '@/router/model';
import { RouteKey } from '@/router/routeKey';
import dayjs from 'dayjs';
import { BindUserItemModel, WechatAction } from '@/api/auth/model';
import { BindWechat, UnbindWechat } from '@/api';
import router from '@/router';
import { ErrorCode, ResultModel } from '@/utils/http';
import { showSuccessToast, showToast } from 'vant';
/**
 * @func app的hooks
 * @desc
 * @param {}
 * @return {}
 */
export function useHook() {
    /**
     * @func 应用进入的时候
     * @desc
     * @param {}
     * @return {}
     */
    const onAppLaunch = () => {
        return new Promise(async (resolve) => {
            try {
                const promiseArr: any[] = [];
                // 获取语言
                promiseArr.push(useLang().getRemoteLang());
                // 获取基础数据
                promiseArr.push(useGlobal().getRemoteBaseData());
                // 获取字典数据
                promiseArr.push(useGlobal().getRemoteDicData());
                // 登录需要处理的事情
                const list = loginRequestList();
                promiseArr.push(...list);
                // 判断设备
                const isMobile = useClient().isMobile();
                // 设置日期的语言
                useLang().loadDayjsLang();
                console.log('是否是移动设备应用环境：', isMobile);
                if (!isMobile) {
                    // VITE_APP_PC_DOMAIN
                    const pcDomain = import.meta.env.VITE_APP_PC_DOMAIN;
                    // 跳转到pc端
                    window.location.href = pcDomain;
                    return;
                }
                await Promise.all(promiseArr);

                // 正式环境才使用aijobSdk
                useAijobJsSdk().init();
            } catch (error) {
                console.log(error);
            } finally {
                resolve(true);
            }
        });
    };

    /**
     * @func 登录需要处理的
     * @desc
     * @param {}
     * @return {}
     */
    const loginRequestList = () => {
        const isLogin = useAuth().isLogin();
        if (!isLogin) {
            return [];
        }
        return [
            // 获取用户信息
            useUser().getRemoteUserInfo(),
            // 获取用户权限
            useUserPermission().getUserPermission(),
            // 获取用户公司信息
            useCompany().getRemoteCompanyInfo(),
            // 获取省份
            useCity().getRemoteProvince(),
            // 获取部门
            useDepartment().getRemoteDepartment()
        ];
    };

    const routeBeforeEach = (to, from, next) => {
        const toMeta = to.meta as RouteMetaModel;
        const { code, action } = to.query;
        if (code && action) {
            useLoading().show();
            const { wechatLogin } = useAuth();
            // 微信登录
            if (action === WechatAction.LoginWechat) {
                wechatLogin(code)
                    .then(() => {
                        useLoading().hide();
                    })
                    .catch((error) => {})
                    .finally(() => {
                        useLoading().hide();
                    });
            }
            if (action === WechatAction.BindWechat) {
                const l = useLang().l;
                BindWechat(code)
                    .then(() => {
                        // 刷新
                        showSuccessToast(l('绑定成功'));
                        setTimeout(() => {
                            useLoading().hide();
                            window.location.reload();
                        }, 1000);
                    })
                    .catch((error) => {
                        console.log(error);
                        useLoading().hide();
                        const res = error as ResultModel;

                        if (res?.errorCode === ErrorCode.AlreadyBind) {
                            const data = res.data[0] as BindUserItemModel;
                            console.log(data);
                            useDialog()
                                .confirm({
                                    message: l(
                                        '此Wechat已经绑定其他账号，您需要解绑以下账号才可以绑定wechat'
                                    ),
                                    confirmButtonText: l('解绑')
                                })
                                .then(async (res) => {
                                    if (res) {
                                        // 解绑
                                        await UnbindWechat(data.userId, data.wxOpenId);
                                        showSuccessToast(l('解绑成功'));
                                        window.location.reload();
                                        // 刷新
                                    }
                                    router.replace({
                                        name: RouteKey.AccountSetting
                                    });
                                });
                        }
                    })
                    .finally(() => {
                        router.replace({
                            name: RouteKey.AccountSetting
                        });
                    });
            }
            next();
            return;
        }

        const isLogin = useAuth().isLogin();
        const loginVerificationType = toMeta.loginVerificationType;
        // 如果不需要登录
        if (loginVerificationType === LoginVerificationType.NO_VERIFICATION) {
            next();
            return;
        }
        // 如果已经登录
        if (isLogin) {
            // 页面必须不登录才可以访问
            if (loginVerificationType === LoginVerificationType.MUST_NOT_LOGIN) {
                // 跳转到首页
                next({
                    name: RouteKey.Index
                });
            }
        } else {
            // 如果需要登录但是没有登录的时候就需要跳转到登录页面
            if (loginVerificationType === LoginVerificationType.MUST_LOGIN) {
                const href = encodeURIComponent(window.location.href);
                next({
                    name: RouteKey.Login,
                    query:
                        to.name !== RouteKey.Index
                            ? {
                                  redirect: href
                              }
                            : {}
                });
            }
        }

        next();
    };

    return { onAppLaunch, routeBeforeEach };
}
