import { OptionItemModel } from '@/model/common';
import ClipboardJS from 'clipboard';
import { showToast } from 'vant';
import { useLang } from './useLang';
/**
 * @func 国际化
 * @desc
 * @param {}
 * @return {}
 */
export function useClient() {
    const l = useLang().l;
    /**
     * @func 获取设备信息，是否是移动端
     * @desc
     * @param {}
     * @return {}
     */
    const isMobile = () => {
        return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    };

    /**
     * @func 是否是微信
     * @desc
     * @param {}
     * @return {}
     */
    const isWechat = () => {
        return /micromessenger/.test(window.navigator.userAgent.toLowerCase());
    };

    //字符串转base64
    const encode = (str) => {
        // 对字符串进行编码
        const encode = encodeURIComponent(str);
        // 对编码的字符串转化base64
        const base64 = btoa(encode);
        return base64;
    };

    // base64转字符串
    const decode = (base64) => {
        try {
            // 对base64转编码
            const decode = window.atob(base64);
            // 编码转字符串
            const str = decodeURIComponent(decode);
            return str;
        } catch (error) {
            console.log(error);
            return '';
        }
    };
    /**
     * @func 复制文字到剪切板
     * @desc
     * @param {}
     * @return {}
     */
    const copyToClipboard = (value: any, type = 'text') => {
        // return new Promise((resolve) => {
        //     // .copy-btn不存在
        //     if (!document.querySelector('.copy')) {
        //         const btn = document.createElement('button');
        //         btn.className = 'copy';
        //         document.body.appendChild(btn);
        //     }
        //     // copyToClipboard('asdasd');
        //     const clipboard = new ClipboardJS('.copy', {
        //         text: () => {
        //             //返回需要复制的字符串
        //             return text;
        //         }
        //     });
        //     clipboard.on('success', () => {
        //         clipboard.destroy();
        //         console.log('复制成功');
        //         showToast('复制成功');
        //         resolve(true);
        //     });
        //     clipboard.on('error', () => {
        //         clipboard.destroy();
        //         console.log('复制失败');
        //         showToast('复制失败');
        //         resolve(false);
        //     });
        // });
        return new Promise((resolve, reject) => {
            const l = useLang().l;
            if (document.execCommand) {
                if (type === 'text') {
                    const ele = document.createElement('textarea');
                    ele.style.cssText = 'position:fixed;top:-9999px;left:-9999px';
                    ele.value = value;
                    document.body.appendChild(ele);
                    ele.select();
                    document.execCommand('Copy');
                    setTimeout(() => {
                        ele.remove();
                    }, 50);
                } else if (type === 'img') {
                    if (!document.queryCommandSupported('copy')) {
                        reject(l('浏览器不支持，请手动操作'));
                        return;
                    }
                    const selection: any = window.getSelection();
                    if (selection.rangeCount > 0) {
                        selection.removeAllRanges();
                    }
                    value.setAttribute('contenteditable', true);
                    const range = document.createRange();
                    range.selectNode(value);
                    selection.addRange(range);
                    document.execCommand('copy');
                    selection.removeAllRanges();
                    value.removeAttribute('contenteditable', true);
                }

                showToast(l('复制成功'));
                resolve(true);
            } else {
                reject(l('抱歉！您的浏览器不支持拷贝功能，建议使用Googel浏览器。'));
            }
        });
    };

    /**
     * @func 去掉window.location.href中的某个参数
     * @desc
     * @param {}
     * @return {}
     */
    const removeUrlParam = (href: string, key: string[]) => {
        const array = href.split('?');
        const url = href.split('?')[0];
        const query = array.length > 1 ? array[1] : '';
        if (!query) {
            return url;
        }
        const params = query.split('&');
        const newParams: string[] = [];
        params.forEach((item) => {
            const keyName = item.split('=')[0];
            if (!key.includes(keyName)) {
                newParams.push(item);
            }
        });
        if (newParams.length === 0) {
            return url;
        }
        return `${url}?${newParams.join('&')}`;
    };

    /**
     * @func 添加参数
     * @desc
     * @param {}
     * @return {}
     */
    const addUrlParam = (href: string, key: string, value: string) => {
        const array = href.split('?');
        const url = href.split('?')[0];
        const query = array.length > 1 ? array[1] : '';
        if (!query) {
            return `${url}?${key}=${value}`;
        }
        const params = query.split('&');
        const newParams: string[] = [];
        let isExist = false;
        params.forEach((item) => {
            const keyName = item.split('=')[0];
            if (keyName === key) {
                newParams.push(`${key}=${value}`);
                isExist = true;
            } else {
                newParams.push(item);
            }
        });
        if (!isExist) {
            newParams.push(`${key}=${value}`);
        }
        return `${url}?${newParams.join('&')}`;
    };

    return { isMobile, isWechat, copyToClipboard, encode, decode, removeUrlParam, addUrlParam };
}
