import { RouteRecordRaw } from 'vue-router';
import { LoginVerificationType } from '../model';
import { RouteKey } from '../routeKey';

export const SettingRoutes: Array<RouteRecordRaw> = [
    {
        path: '/setting',
        component: () => import('@/layout/index.vue'),
        redirect: '/system',
        // 需要layout的页面
        children: [
            {
                path: 'system',
                name: RouteKey.SystemSetting,
                meta: {
                    title: '系统设置',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/setting/system/index.vue')
            },
            {
                path: 'account',
                name: RouteKey.AccountSetting,
                meta: {
                    title: '账户设置',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/setting/account/index.vue')
            },
            {
                path: 'resetPassword',
                name: RouteKey.ResetPassword,
                meta: {
                    title: '重置密码',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/setting/resetPassword/index.vue')
            }
        ]
    }
];
