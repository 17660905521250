import { RouteRecordRaw } from 'vue-router';
import { LoginVerificationType } from '../model';
import { RouteKey } from '../routeKey';

export const TaskRoutes: Array<RouteRecordRaw> = [
    {
        path: '/task',
        component: () => import('@/layout/index.vue'),
        redirect: '/detail',
        children: [
            {
                path: 'detail/:id',
                name: RouteKey.TaskDetail,
                meta: {
                    title: '日程详情',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/task/detail/index.vue')
            },
            // 分享的日程
            {
                path: 'shareTaskDetail/:id',
                name: RouteKey.TaskShareDetail,
                meta: {
                    title: '日程详情',
                    loginVerificationType: LoginVerificationType.NO_VERIFICATION
                },
                component: () => import('@/pages/task/shareDetail/index.vue')
            },
            // 月视图
            {
                path: 'month',
                name: RouteKey.TaskMonth,
                meta: {
                    title: '月视图',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/task/monthView/index.vue')
            },
            // 团队视图
            {
                path: 'team',
                name: RouteKey.TaskTeam,
                meta: {
                    title: '团队视图',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/task/taskTeam/index.vue')
            }
        ]
    }
];
