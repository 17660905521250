/**
 * @func 加载第三方js
 * @desc
 * @param {}
 * @return {}
 */
const RegConfig = {
    //邮箱正则表达式,需要带上@和后缀
    Email: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
    // 正整数
    PositiveInteger: /^[1-9]\d*$/,
    // 不能有空格
    NoSpace: /^\S*$/,
    // 长度为200字以内
    Length200: /^.{0,200}$/,
    // 20字
    Length20: /^.{0,20}$/,
    // 50字
    Length50: /^.{0,50}$/,
    // 100字
    Length100: /^.{0,100}$/,
    // 数字
    Number: /^[0-9]*$/,
    // ip不带端口
    Ip: /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/,
    // ip带端口
    IpPort: /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?):\d{1,5}$/,
    // 大于6位数的数字
    Phone: /^[0-9]{6,}$/,
    // 大于6位的文字
    Password: /^.{6,}$/,
    // trim
    Trim: /^\s*|\s*$/g
};
export enum RegEnum {
    Email = 'Email',
    PositiveInteger = 'PositiveInteger',
    //不能有空格
    NoSpace = 'NoSpace',
    // 长度为200字以内
    Length200 = 'Length200',
    // 数字
    Number = 'Number',
    // ip不带端口
    Ip = 'Ip',
    // ip带端口
    IpPort = 'IpPort',
    // Phone
    Phone = 'Phone',
    // Password
    Password = 'Password',
    // Length20
    Length20 = 'Length20',
    // Length50
    Length50 = 'Length50',
    // Length100
    Length100 = 'Length100',
    // 500
    Length500 = 'Length500',
    // 1000
    Length1000 = 'Length1000',
    // Trim
    Trim = 'Trim'
}
export function useReg() {
    // 验证
    const testValue = (value: string, type: RegEnum) => {
        const reg = RegConfig[type];
        return new RegExp(reg).test(value);
    };

    // 验证手机号或者邮箱
    const testPhoneOrEmail = (value: string) => {
        console.log(value);
        return testValue(value, RegEnum.Email) || testValue(value, RegEnum.Phone);
    };

    // 验证邮箱
    const testEmail = (value: string) => {
        return testValue(value, RegEnum.Email);
    };

    // 50普通文本限制
    const text20 = (value: string) => {
        return testValue(value, RegEnum.Length20);
    };
    // 50普通文本限制
    const text50 = (value: string) => {
        return testValue(value, RegEnum.Length50);
    };
    // 100普通文本限制
    const text100 = (value: string) => {
        return testValue(value, RegEnum.Length100);
    };
    // 200普通文本限制
    const text200 = (value: string) => {
        return testValue(value, RegEnum.Length200);
    };
    // 500
    const text500 = (value: string) => {
        return testValue(value, RegEnum.Length500);
    };
    // 1000
    const text1000 = (value: string) => {
        return testValue(value, RegEnum.Length1000);
    };
    // 去掉两端空格
    const trim = (value: string) => {
        return value.replace(RegConfig.Trim, '');
    };

    return {
        testValue,
        testPhoneOrEmail,
        testEmail,
        text20,
        text50,
        text100,
        text200,
        trim,
        text500,
        text1000
    };
}
