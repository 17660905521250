import http, { ResultModel } from '@/utils/http';
import { UpdateUserInfoParamModel, UserInfoModel } from './model';

const Address = {
    // 获取基础数据
    // 修改用户信息
    UpdateUserInfo: '/recruit-user-info/update',
    // 获取用户信息
    GetUserInfo: '/recruit-user-info'
};

// 获取用户信息
export async function GetUserInfo(): Promise<ResultModel<UserInfoModel>> {
    return await http.get<ResultModel<UserInfoModel>>(`${Address.GetUserInfo}`);
}

// 修改用户信息
export async function UpdateUserInfo(
    param: UpdateUserInfoParamModel
): Promise<ResultModel<UserInfoModel>> {
    return await http.post<ResultModel<UserInfoModel>>(`${Address.UpdateUserInfo}`, param);
}

// 获取本地用户信息
// export async function GetUserInfo(): Promise<UserInfoModel> {
//     const str = localStorage.getItem('authUserInfo');
//     if (str) {
//         return Promise.resolve(JSON.parse(str));
//     } else {
//         return this.GetRemoteUserInfo();
//     }
// }

// 修改用户信息
// export async function UpdateUserInfo(param: UserInfoModel): Promise<void> {
//     try {
//         const result = await request(
//             `${this.Address.UpdateUserInfo}`,
//             {
//                 ...param
//             },
//             'POST'
//         );
//         const data = result.data as UserInfoModel;
//         getLocalStorage().setItem('companyId', data.companyId);
//         getLocalStorage().setItem('authUserInfo', JSON.stringify(data));
//         return Promise.resolve();
//     } catch (error) {
//         throw error;
//     }
// }
