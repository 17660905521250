import { RouteRecordRaw } from 'vue-router';
import { LoginVerificationType } from '../model';
import { RouteKey } from '../routeKey';

export const RecruiterRoutes: Array<RouteRecordRaw> = [
    {
        path: '/recruiter',
        component: () => import('@/layout/index.vue'),
        redirect: '/index',
        // 需要layout的页面
        children: [
            {
                path: 'inviter',
                name: RouteKey.RecruiterInviter,
                meta: {
                    title: '邀请同事加入企业',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/recruiter/inviter/index.vue')
            },
            {
                path: 'apply',
                name: RouteKey.RecruiterApply,
                meta: {
                    title: '申请列表',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/recruiter/apply/index.vue')
            },
            {
                path: 'info',
                name: RouteKey.RecruiterInfo,
                meta: {
                    title: '',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/recruiter/info/index.vue')
            },
            {
                path: 'leave',
                name: RouteKey.RecruiterLeave,
                meta: {
                    title: '',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/recruiter/info/index.vue')
            },
            // applyJoin
            {
                path: 'applyJoin',
                name: RouteKey.RecruiterApplyJoin,
                meta: {
                    title: '邀请你加入公司',
                    loginVerificationType: LoginVerificationType.NO_VERIFICATION
                },
                component: () => import('@/pages/recruiter/applyJoin/index.vue')
            }
        ]
    }
];
