import { RouteRecordRaw } from 'vue-router';
import { LoginVerificationType } from '../model';
import { RouteKey } from '../routeKey';

export const OrganizationRoutes: Array<RouteRecordRaw> = [
    {
        path: '/organization',
        component: () => import('@/layout/index.vue'),
        redirect: '/index',
        // 需要layout的页面
        children: [
            {
                path: 'index',
                name: RouteKey.OrganizationInfo,
                meta: {
                    title: '组织架构',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/organization/index.vue')
            }
        ]
    }
];
