<template>
    <div>
        <van-config-provider
            v-if="loadFinish"
            :theme-vars="themeVars"
            theme-vars-scope="global">
            <router-view
        /></van-config-provider>
        <div
            v-else
            class="loading_view">
            <van-skeleton
                title
                :row="3"
                :loading="true">
                <div></div>
            </van-skeleton>
        </div>
    </div>
</template>

<script setup lang="ts">
    import debug from '@/utils/debug';
    import watermark from '@/utils/lib/watermark';
    import copyPaste from '@/utils/lib/copy-paste';
    import { useTheme, useHook, useLoading } from '@/hooks';
    const loadFinish = ref(false);
    const themeVars = useTheme().themeVars;
    onMounted(async () => {
        // useLoading().show();
        loadFinish.value = true;
        // 因为debug是存入localStorage中的，刷新页面会从localStorage取出，根据debug控制是否隐藏
        debug.init();
        // const { username = '', mobile = '' } = auth.getUser();
        // watermark.add({
        // content: username + ' ' + mobile,
        // });
        // copyPaste.disable();
        await useHook().onAppLaunch();
        // useLoading().hide();
    });

    onBeforeUnmount(() => {
        watermark.remove();
        copyPaste.enable();
    });
</script>

<style>
    #app {
        font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
        font-synthesis: none;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-text-size-adjust: 100%;
    }
    .loading_view {
        padding: 80px 0px;
    }
</style>
