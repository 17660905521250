export type AuthPhoneVerficationLoginParamModel = {
    phone?: string;
    phoneCode?: string;
    code?: string;
    type?: string;
};

// 手机号+密码登录
// {
//     "phone": "15549072001",
//     "phoneCode": "86",
//     "password": "123123",
//     "type": "PhonePassword"
// }
export type AuthPhonePasswordLoginParamModel = {
    phone?: string;
    phoneCode?: string;
    password?: string;
    type?: string;
};

// 邮箱+密码登录
// {
//     "email": "memeda666@gmail.com",
//     "code": "2028",
//     "type": "EmailVerification"
// }
export type AuthEmailVerficationLoginParamModel = {
    email?: string;
    code?: string;
    type?: string;
};

// 邮箱+密码登录
// {
//     "email": "458148170@qq.com",
//     "password": "123123123",
//     "type": "EmailPassword"
// }

export type AuthEmailPasswordLoginParamModel = {
    email?: string;
    password?: string;
    type?: string;
};

// 修改密码的参数
export type AuthChangePasswordParamModel = {
    oldPassword?: string;
    newPassword?: string;
};

// 修改手机号的参数
export type AuthChangePhoneParamModel = {
    phone?: string;
    phoneCode?: string;
    code?: string;
};

// 修改邮箱
export type AuthChangeEmailParamModel = {
    email?: string;
    code?: string;
};

// 登录类型枚举
export enum LoginType {
    None = 'None',
    PhoneVerification = 'PhoneVerification',
    EmailVerification = 'EmailVerification',
    PhonePassword = 'PhonePassword',
    EmailPassword = 'EmailPassword',
    // 微信登录
    Wechat = 'Wechat',
    // 微信开放平台登录
    WechatOpenPlatform = 'WechatOpenPlatform',
    // line
    Line = 'Line'
}

// 权限标识
export enum PermissionKey {
    // 是否显示问卷删除按钮
    Question_Show_Delete_Button = 'Question_Show_Delete_Button',
    // 创建商品的权限
    Menu_Show_CreateGoods = 'Menu_Show_CreateGoods',
    // 启用/禁用商品的权限
    Goods_Show_EnableGoods = 'Goods_Show_EnableGoods'
}

// 用户类型
export enum ApplicationName {
    // 无
    None = 'none',
    // 普通用户
    Seeker = 'seeker',
    // 招聘者
    Recruiter = 'recruiter',
    // 管理员
    Admin = 'admin',
    // 客服
    Customer = 'customer',
    // 机器人
    Robot = 'robot'
}

export const ApplicationNameColor = {
    [ApplicationName.Seeker]: {
        backgroundColor: 'var(--green1)',
        color: 'var(--green)'
    },
    [ApplicationName.Recruiter]: { backgroundColor: 'var(--blue1)', color: 'var(--blue)' },
    [ApplicationName.Admin]: { backgroundColor: 'var(--red1)', color: 'var(--red)' },
    [ApplicationName.Customer]: { backgroundColor: 'var(--orange1)', color: 'var(--orange)' },
    [ApplicationName.Robot]: { backgroundColor: 'var(--purple1)', color: 'var(--purple)' }
};

// line的action
export enum LineAction {
    // none
    None = 'None',
    // 绑定
    BindLine = 'bindline',
    // 解绑
    LoginLine = 'loginline'
}
// WechatAction
export enum WechatAction {
    // none
    None = 'None',
    // 绑定
    BindWechat = 'bindwechat',
    // 解绑
    LoginWechat = 'loginwechat'
}

// 绑定用户item
export type BindUserItemModel = {
    userId: string;
    phone?: string;
    phoneCode?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    head?: string;
    companyName?: string;
    position?: string;
    lineOpenId: string;
    wxOpenId: string;
};

// 登录结果
export interface LoginResultModel {
    token: string;
    imSecret: string;
    userId: string;
}
