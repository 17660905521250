import http, { ResultModel } from '@/utils/http';
import { DicModel } from './model';

const Address = {
    // 获取基础数据
    AllData: '/dic/all'
};

export async function GetDicData() {
    return await http.get<ResultModel<DicModel>>(Address.AllData);
}
