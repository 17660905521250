import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router';
import { TabRoutes } from './config/tab';
import { useHook } from '@/hooks';
import { CompanyRoutes } from './config/company';
import { LoginRoutes } from './config/login';
import { UserRoutes } from './config/user';
import { SettingRoutes } from './config/setting';
import { OrganizationRoutes } from './config/organization';
import { RecruiterRoutes } from './config/recruiter';
import { TaskRoutes } from './config/task';
import { ResultRoutes } from './config/result';
const routes: Array<RouteRecordRaw> = [
    ...TabRoutes,
    ...CompanyRoutes,
    ...LoginRoutes,
    ...UserRoutes,
    ...SettingRoutes,
    ...OrganizationRoutes,
    ...RecruiterRoutes,
    ...TaskRoutes,
    ...ResultRoutes,
    // 替代vue2中的'*'通配符路径
    { path: '/:pathMatch(.*)*', redirect: '/result/notfound' }
];

const router = createRouter({
    history: createWebHistory(), // history 模式则使用 createWebHistory()
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    }
});

/**
 * @func 注册路由守卫
 * @desc
 * @param {}
 * @return {}
 */
router.beforeEach(useHook().routeBeforeEach);

export default router;
