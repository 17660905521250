import { useLoaderJs, useLang } from './index';
const l = useLang().l;
export interface ShareConfig {
    title: string;
    desc: string;
    link: string;
    imgUrl?: string;
}

export function useAijobJsSdk() {
    // let toast: any = null;

    const init = () => {
        const env = import.meta.env.VITE_APP_ENV;
        if (import.meta.env.VITE_APP_ENV !== 'production') return;
        return new Promise(async (resolve, reject) => {
            try {
                if (window['aijob']) {
                    return resolve(window['aijob']);
                }
                // 加载js
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const aijob: any = await useLoaderJs().aijob();
                aijob.wxShare.init({
                    // 是否需要打印log
                    log: true,
                    // 环境
                    env: env
                });
                // 初始化监控
                aijob.clientMonitor.init(env);
                // 注册分享
                registerShare();
                return resolve(aijob);
            } catch (error) {
                console.log(error);
            }
        });
    };

    // 设置分享
    const registerShare = (param = {}) => {
        if (import.meta.env.VITE_APP_ENV !== 'production') return;

        return new Promise(async (resolve, reject) => {
            // 默认分享地址
            const defaultShareConfig: ShareConfig = {
                title: 'AIjob Bissniss Management',
                desc: l('AIjob企业管理端'),
                link: import.meta.env.VITE_APP_DOMAIN,
                imgUrl: import.meta.env.VITE_APP_DOMAIN + '/logo.png'
            };
            // logo默认地址VITE_APP_DOMAIN
            const config = { ...defaultShareConfig, ...param };
            try {
                // 加载js
                const aijob: any = await init();
                await aijob.wxShare.registerShare({
                    ...config,
                    success: function () {
                        console.log('分享成功');
                    },
                    cancel: function () {
                        console.log('分享取消');
                    }
                });
                console.log('注册分享成功');
                resolve(true);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    // 设置监控userId
    const setClientMonitorUserId = (userId: string) => {
        if (import.meta.env.VITE_APP_ENV !== 'production') return;

        return new Promise(async (resolve, reject) => {
            try {
                // 加载js
                const aijob: any = await init();
                aijob.clientMonitor.setUserId(userId);
                console.log('设置监控userId成功');
            } catch (error) {
                console.log(error);
            }
        });
    };

    return { init, registerShare, setClientMonitorUserId };
}
