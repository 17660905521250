import http, { ResultModel } from '@/utils/http';
import { OptionItemModel } from '@/model/common';

const Address = {
    // 获取基础数据
    GetAllProvince: '/city/getAllProvince',
    GetCity: '/city/getCity',
    GetArea: '/city/getArea'
};

// 获取全部省份
export async function GetAllProvince(): Promise<ResultModel<OptionItemModel[]>> {
    return await http.get<ResultModel<OptionItemModel[]>>(Address.GetAllProvince);
}

// 获取城市
export async function GetCity(id: string): Promise<ResultModel<OptionItemModel[]>> {
    return await http.get<ResultModel<OptionItemModel[]>>(Address.GetCity, { id });
}

// 获取区域
export async function GetArea(id: string): Promise<ResultModel<OptionItemModel[]>> {
    return await http.get<ResultModel<OptionItemModel[]>>(Address.GetArea, { id });
}
