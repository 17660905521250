/* eslint-disable @typescript-eslint/no-explicit-any */

// 和后端约定好接口返回的数据结构
// export interface Response<T = any> {
//     code: number | string;
//     message: string;
//     result: T;
// }

export interface ResultModel<T = any> {
    data: T;
    code: number;
    errorCode: number;
    msg: string;
    total?: number;
    description: string;
}

export interface RequestConfig {
    // 弹窗是否显示
    isShowModal?: boolean;
    // 是否显示错误信息
    isShowError?: boolean;
}

export const ErrorCode = {
    //正常
    Success: 0,
    //密码错误
    PasswordError: 2,
    //用户不存在
    UserNotExist: 3,
    //同步用户失败
    SyncUserError: 4,
    //普通错误
    Error: 5,
    //不存在记录
    NotExist: 6,
    //已存在
    Exist: 10,
    //系统异常
    System: 7,
    //超时
    TimeOut: 8,
    //不可用
    Unavailable: 9,
    //token验证没有通过
    TokenError: 401,
    //参数异常
    ParamError: 402,
    //没有加入公司
    NotAddCompany: 11,
    //第三方服务无法使用
    ThirdServiceError: 10,
    //权限异常
    PermissionError: 403,
    //没有设置简历模板
    NotSetResumeTemplate: 12,
    // 没有开通店铺
    NotOpenStore: 13,
    //超过最大值
    Max: 13,
    // 低于最小值
    Min: 14,
    // 没有开放访问
    NotOpenAccess: 15,
    // 第三方接口异常
    ThirdError: 16,
    // 简历完整度不够
    ResumeCompletionNotEnough: 17,
    // 过期
    Expired: 18,
    // 已经绑定
    AlreadyBind: 19
};
