import http, { ResultModel } from '@/utils/http';

const Address = {
    // 获取当前语言的国际化
    GetLanguageForCountry: '/language/forCountry'
};

export async function GetLanguageForCountry() {
    return await http.get<ResultModel<Record<string, string>>>(Address.GetLanguageForCountry);
}
