import { GetRemoteMyCompanyInfo } from '@/api/index';
import { CompanyDetailModel } from '@/api/company/model';
import { ErrorCode, ResultModel } from '@/utils/http';
import { RouteKey } from '@/router/routeKey';
import router from '@/router';

/**
 * @func 用户信息
 * @desc
 * @param {}
 * @return {}
 */
export function useCompany() {
    /**
     * @func 获取远程用户信息
     * @desc
     * @param {}
     * @return {}
     */
    const getRemoteCompanyInfo = async (): Promise<CompanyDetailModel> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await GetRemoteMyCompanyInfo(false);
                // 保存到本地
                localStorage.setItem('companyInfo', JSON.stringify(res.data || {}));
                localStorage.setItem('companyId', res.data.id || '');
                resolve(res.data);
            } catch (error) {
                const data = error as ResultModel;
                const { errorCode } = data;
                if (errorCode === ErrorCode.NotAddCompany) {
                    // 跳转到创建公司页面
                    router.push({
                        name: RouteKey.SwitchCreateCompany
                    });
                }
                reject(error);
            }
        });
    };

    /**
     * @func 获取公司信息
     * @desc
     * @param {}
     * @return {}
     */
    const getCompanyInfo = (): CompanyDetailModel => {
        const companyInfo = localStorage.getItem('companyInfo');
        const companyInfoContent = companyInfo ? JSON.parse(companyInfo || '{}') : {};
        return companyInfoContent;
    };

    return { getCompanyInfo, getRemoteCompanyInfo };
}
