import { GetBaseData, GetDepartment, GetDicData, GetUserInfo, UpdateUserInfo } from '@/api/index';
import { useLang } from './useLang';
import { DepartmentItemModel } from '@/api/departmemnt/model';

/**
 * @func 部门信息
 * @desc
 * @param {}
 * @return {}
 */
export function useDepartment() {
    /**
     * @func 获取远程部门列表
     * @desc
     * @param {}
     * @return {}
     */
    const getRemoteDepartment = async (): Promise<DepartmentItemModel[]> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await GetDepartment();
                // 保存到本地
                localStorage.setItem('departmemnt', JSON.stringify(res.data || {}));
                resolve(res.data);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    /**
     * @func 获取部门信息
     * @desc
     * @param {}
     * @return {}
     */
    const getDepartment = (): DepartmentItemModel[] => {
        const departmemnt = localStorage.getItem('departmemnt');
        const departmemntContent = departmemnt ? JSON.parse(departmemnt || '[]') : [];
        return departmemntContent;
    };

    /**
     * @func 通过父级部门id获取子部门
     * @desc
     * @param {}
     * @return {}
     */
    // const getChildrenDepartment = (parentId: string): DepartmentItemModel[] => {
    //     const departmemnt = localStorage.getItem('departmemnt');
    //     const departmemntContent = departmemnt ? JSON.parse(departmemnt || '[]') : [];
    //     return departmemntContent.filter((item: DepartmentItemModel) => item.parentId === parentId);
    // }

    return { getRemoteDepartment, getDepartment };
}
