import {
    AuthEmailPasswordLoginParamModel,
    AuthEmailVerficationLoginParamModel,
    AuthPhonePasswordLoginParamModel,
    AuthPhoneVerficationLoginParamModel,
    LoginType,
    WechatAction
} from '@/api/auth/model';
import { useLang } from './useLang';
import { RegEnum, useReg } from './useReg';
import { useClient } from './useClient';

import { GetUserInfo, Login } from '@/api';
import { useAijobJsSdk } from './useAijobJsSdk';
import { PasswordLoginParamModel } from '@/pages/login/component/passwordLoginView/index.model';
import { LoginResultModel } from '@/api/auth/model';
import router from '@/router';
import { useDialog } from './useDialog';
import { useUser } from './useUser';
import { useUserPermission } from './useUserPermission';
import { useCompany } from './useCompany';
import { VerificationLoginParamModel } from '@/pages/login/component/verificationLoginView/index.model';
import { RouteKey } from '@/router/routeKey';
import { useGlobal } from './useGlobal';
// 登录配置
export interface LoginConfig {
    isSaveToken: boolean;
    redirectUrl?: string;
}

/**
 * @func 用户鉴权
 * @desc
 * @param {}
 * @return {}
 */
export function useAuth() {
    /**
     * @func 是否登录
     * @desc
     * @param {}
     * @return {}
     */
    const isLogin = () => {
        const isLogin = localStorage.getItem('token');
        return !!isLogin;
    };

    /**
     * @func 获取上次登录的账号
     * @desc
     * @param {}
     * @return {}
     */
    const getLastLoginAccount = () => {
        const { decode } = useClient();
        const account = localStorage.getItem('account');
        return account ? decode(account) : '';
    };

    /**
     * @func 跳转微信
     * @desc
     * @param {}
     * @return {}
     */
    const toWechat = (action: WechatAction) => {
        // - redirectUrl：重定向地址，需要encodeURIComponent后的地址
        // - scope：作用于，在此处用于微信登录所以填写code这个字符串即可
        // - timestamp：当前时间戳毫秒值，防止出现页面缓存
        // - lang：国家号，如果不清楚国家号定义，可以参考
        // 去掉window.location.href中的code参数
        const { removeUrlParam, addUrlParam } = useClient();
        let href = window.location.href;
        href = removeUrlParam(href, ['code', 'timestamp', 'action']);
        href = addUrlParam(href, 'action', action);

        const redirectUrl = encodeURIComponent(href);
        // 添加action参数

        const scope = 'code';
        const timestamp = new Date().getTime();
        const lang = useLang().getLocal();
        // 公共服务域名
        const host = import.meta.env.VITE_APP_PUBLIC_DOMAIN;
        const url = `${host}/login/wechat?redirectUrl=${redirectUrl}&scope=${scope}&timestamp=${timestamp}&lang=${lang}`;
        // 跳转replace
        window.location.replace(url);
    };

    // // 获取微信code
    // const toWechat2 = (action: WechatAction) => {
    //     try {
    //         const { getBaseData } = useGlobal();

    //         // const global = await Service.applicationService.GetRemoteGlobalBaseInfo();
    //         // // 获取参数
    //         // const env = process.env.NEXT_PUBLIC_DOMAIN_ENV;
    //         // let redirect_uri = `${window.location.origin}/auth/wechat`;
    //         // if (env === EnvEnum.DEVELOPMENT) {
    //         //     ;
    //         // }
    //         let redirect_uri = 'https://recruit-aijob.tolern.com/auth/wechat';

    //         const appId = getBaseData().openPlatform?.appId || '';
    //         redirect_uri = encodeURIComponent(redirect_uri);
    //         const state = `${action}${new Date().getTime()}`;
    //         const scope = 'snsapi_login';
    //         const response_type = 'code';
    //         const url = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&state=${state}#wechat_redirect`;
    //         // // 跳转
    //         // window.location.href = url;
    //         window.open(url, '_self');
    //     } catch (error) {
    //         // throw error;
    //     }
    // };

    /**
     * @func 跳转line
     * @desc
     * @param {}
     * @return {}
     */
    const toLine = () => {
        const redirectUrl = encodeURIComponent(window.location.href);
        const scope = 'code';
        const timestamp = new Date().getTime();
        const lang = useLang().getLocal();
        const host = import.meta.env.VITE_APP_PUBLIC_DOMAIN;
        const url = `${host}/login/line?redirectUrl=${redirectUrl}&scope=${scope}&timestamp=${timestamp}&lang=${lang}`;
        // 跳转replace
        window.open(url, '_self');
    };

    /**
     * @func 用户登录，这里需要处理账号转成手机号或者邮箱
     * @desc
     * @param {}
     * @return {}
     */
    const passwordLogin = async (
        param: PasswordLoginParamModel,
        config: LoginConfig = {
            isSaveToken: true
        }
    ) => {
        return new Promise(async (resolve, reject) => {
            const { account } = param;
            const { testValue } = useReg();
            let loginParam: AuthPhonePasswordLoginParamModel | AuthEmailPasswordLoginParamModel =
                {};
            // 手机号登录
            if (testValue(account, RegEnum.Phone)) {
                // 组装
                loginParam = {
                    phone: account,
                    password: param.password,
                    type: LoginType.PhonePassword
                };
            }

            // 邮箱登录
            if (testValue(account, RegEnum.Email)) {
                // 组装
                loginParam = {
                    email: account,
                    password: param.password,
                    type: LoginType.EmailPassword
                };
            }
            try {
                // 调用登录接口
                const res = await Login(loginParam);
                const data = res?.data;
                config?.isSaveToken && localStorage.setItem('token', data.token);
                // // 设置统计id
                await loginAfter(data, account);
                loginComplete();
                resolve(true);
            } catch (error) {
                // 抛出异常
                reject(error);
            }
        });
    };

    /**
     * @func 验证码登录
     * @desc
     * @param {}
     * @return {}
     */
    const verificationLogin = async (
        param: VerificationLoginParamModel,
        config: LoginConfig = {
            isSaveToken: true
        }
    ) => {
        return new Promise(async (resolve, reject) => {
            const { account } = param;
            const { testValue } = useReg();
            let loginParam:
                | AuthPhoneVerficationLoginParamModel
                | AuthEmailVerficationLoginParamModel = {};
            // 手机号登录
            if (testValue(account, RegEnum.Phone)) {
                // 组装
                loginParam = {
                    phone: account,
                    code: param.code,
                    type: LoginType.PhoneVerification
                };
            }

            // 邮箱登录
            if (testValue(account, RegEnum.Email)) {
                // 组装
                loginParam = {
                    email: account,
                    code: param.code,
                    type: LoginType.EmailVerification
                };
            }
            try {
                // 调用登录接口
                const res = await Login(loginParam);
                const data = res?.data;
                config?.isSaveToken && localStorage.setItem('token', data.token);
                // 设置统计id
                await loginAfter(data, account);
                loginComplete();

                resolve(true);
            } catch (error) {
                // 抛出异常
                reject(error);
            }
        });
    };

    /**
     * @func 微信登录
     * @desc
     * @param { code: string }
     * @return {}
     */
    const wechatLogin = async (code: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const param: AuthPhoneVerficationLoginParamModel = {
                    code,
                    type: LoginType.Wechat
                };
                // 调用登录接口
                const res = await Login(param);
                const data = res?.data;
                localStorage.setItem('token', data.token);
                // 设置统计id
                await loginAfter(data);
                loginComplete();
                resolve(true);
            } catch (error) {
                console.log(error);
                // 抛出异常
                reject(error);
            }
        });
    };

    /**
     * @func 登录后需要做的事情
     * @desc
     * @param {}
     * @return {}
     */
    const loginAfter = (param: LoginResultModel, account = '') => {
        return new Promise(async (resolve, reject) => {
            try {
                useAijobJsSdk().setClientMonitorUserId(param.userId);
                // 获取用户信息
                await useUser().getRemoteUserInfo();
                // 获取权限GetRemoteUserInfo
                await useUserPermission().getRemoteUserPermission();
                // 获取我的公司信息
                await useCompany().getRemoteCompanyInfo();
                // 存储账号
                const { encode } = useClient();
                account && localStorage.setItem('account', encode(account));
                resolve(true);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * @func 登录完成
     * @desc
     * @param {}
     * @return {}
     */
    const loginComplete = () => {
        const query = router.currentRoute.value.query;
        const redirectUrl = query?.redirectUrl || '';
        setTimeout(() => {
            // 查看是否有redirect参数如果没有就跳转到首页
            if (redirectUrl) {
                location.replace(decodeURIComponent(`${redirectUrl}`));
            } else {
                router.replace({
                    name: RouteKey.Index
                });
            }
        }, 500);
    };

    /**
     * @func 退出登录
     * @desc
     * @param {}
     * @return {}
     */
    const loginOut = async (isConfirm = true) => {
        let result = true;
        if (isConfirm) {
            const { confirm } = useDialog();
            const l = useLang().l;
            result = await confirm({
                message: `${l('确定退出登录吗')}？`
            });
        } else {
            result = true;
        }

        if (result) {
            localStorage.removeItem('token');
            localStorage.removeItem('companyInfo');
            localStorage.removeItem('companyId');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userPermission');

            localStorage.removeItem('userPermission');
            localStorage.removeItem('departmemnt');
            localStorage.removeItem('permission');
            location.reload();
        }
    };

    return {
        isLogin,
        toWechat,
        toLine,
        passwordLogin,
        loginOut,
        verificationLogin,
        getLastLoginAccount,
        wechatLogin
    };
}
