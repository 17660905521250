import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { ErrorCode, RequestConfig, type ResultModel } from './types';
// import { auth } from '@/utils';
import { showToast } from 'vant';
import router from '@/router';
import { useAuth, useLang } from '@/hooks';
import { RouteKey } from '@/router/routeKey';

axios.defaults.timeout = 1000 * 60;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 创建axios实例
const service = axios.create({
    // 根据不同env设置不同的baseURL
    baseURL: import.meta.env.VITE_APP_API_BASE_URL
});

// axios实例拦截请求
service.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        config.url = `${import.meta.env.VITE_APP_API_HOST}${config.url}`;
        const token = localStorage.getItem('token') || '';
        const companyId = localStorage.getItem('companyId') || '';
        const local = useLang().getLocal();
        config.headers = {
            ...config.headers,
            // ...auth.headers(), // 你的自定义headers，如token等
            'Content-Type': 'application/json',
            Accept: 'application/json',
            clienttype: 'recruiterWeb',
            applicationname: 'recruiter',
            lang: local,
            timestamp: `${new Date().getTime()}`,
            'tjob-token': token,
            companyid: companyId,
            // timezoneoffset
            timezoneoffset: `${-Number(new Date().getTimezoneOffset()) / 60}`
        };
        // 默认值
        const defaultConfig: RequestConfig = {
            isShowError: true,
            isShowModal: false
        };
        config = {
            ...defaultConfig,
            ...config
        };
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

// axios实例拦截响应
service.interceptors.response.use(
    // 2xx时触发
    (response: AxiosResponse<ResultModel>) => {
        const config = response.config as AxiosRequestConfig & RequestConfig;
        const { isShowError } = config;
        // response.data就是后端返回的数据，结构根据你们的约定来定义
        const { code, msg, errorCode } = response.data;
        let errMessage = '';
        if (code === 0) {
            return response;
        } else if (errorCode === ErrorCode.TokenError) {
            console.log('登录信息已经失效');
            useAuth().loginOut(false);
            // router.replace({
            //     name: RouteKey.Login,
            //     query: {
            //         redirect: router.currentRoute.value.fullPath
            //     }
            // });
            return Promise.reject(response);
        } else {
            errMessage = msg;
            if (errMessage && isShowError) {
                showToast(errMessage);
            } else {
                // toast消失，不显示错误信息
            }
            return Promise.reject(response);
        }
    },
    // 非2xx时触发
    (error: AxiosError) => {
        console.log(error);
        showToast('network error');
        return Promise.reject({
            code: ErrorCode.System,
            errorCode: ErrorCode.System,
            msg: '服务端异常',
            description: '服务端异常',
            data: null
        });
    }
);

export type { AxiosResponse, AxiosRequestConfig };

export default service;
