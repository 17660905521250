import { GetUserPermission } from '@/api';
import { CompanyDetailModel } from '@/api/company/model';

/**
 * @func 用户权限
 * @desc
 * @param {}
 * @return {}
 */
export function useUserPermission() {
    /**
     * @func 获取远程用户信息
     * @desc
     * @param {}
     * @return {}
     */
    const getRemoteUserPermission = async (): Promise<string[]> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await GetUserPermission();
                // 保存到本地
                localStorage.setItem('userPermission', JSON.stringify(res.data || {}));
                resolve(res.data);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    /**
     * @func 获取用户信息
     * @desc
     * @param {}
     * @return {}
     */
    const getUserPermission = (): CompanyDetailModel => {
        const permission = localStorage.getItem('userPermission');
        const permissionContent = permission ? JSON.parse(permission || '{}') : {};
        return permissionContent;
    };

    return { getRemoteUserPermission, getUserPermission };
}
