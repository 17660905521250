/* eslint-disable @typescript-eslint/no-explicit-any */
import { showLoadingToast, closeToast } from 'vant';

export interface LoadingConfig {
    // 跳转页面loading是否消失
    pageChangeHide?: boolean;
    // 文本
    message?: string;
}

/**
 * @func loading
 * @desc
 * @param {
 * }
 * @return {}
 */
export function useLoading() {
    let toast: any = null;
    const showText = (message: string) => {
        show({
            message
        });
    };
    const show = (config: LoadingConfig = {}) => {
        // 默认值
        const defaultConfig = {
            pageChangeHide: true,
            message: 'loading...'
        };
        config = {
            ...defaultConfig,
            ...config
        };
        toast = showLoadingToast({
            duration: 0,
            forbidClick: true,
            zIndex: 9999,
            ...config
        });
        config?.pageChangeHide && onDeactivated(hide);
    };
    const hide = () => {
        closeToast();
    };

    return { show, hide, showText };
}
