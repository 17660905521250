/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { showToast } from 'vant';
import service, { AxiosRequestConfig } from './axios';
import { ErrorCode, RequestConfig } from './types';
export * from './types';

export const request = <T = any>(config: AxiosRequestConfig & RequestConfig): Promise<T> => {
    return new Promise((resolve, reject) => {
        service
            .request(config)
            .then((res) => {
                // 一些业务处理
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.data);
            });
    });
};

const http = {
    get<T = any>(
        url: string,
        params = {},
        config?: AxiosRequestConfig & RequestConfig
    ): Promise<T> {
        return request({ url, params, ...config, method: 'GET' });
    },
    post<T = any>(url: string, data = {}, config?: AxiosRequestConfig & RequestConfig): Promise<T> {
        return request({ url, data, ...config, method: 'POST' });
    },
    put<T = any>(url: string, data = {}, config?: AxiosRequestConfig & RequestConfig): Promise<T> {
        return request({ url, data, ...config, method: 'PUT' });
    },
    delete<T = any>(
        url: string,
        data = {},
        config?: AxiosRequestConfig & RequestConfig
    ): Promise<T> {
        return request({ url, data, ...config, method: 'DELETE' });
    },
    // 上传文件，指定 'Content-Type': 'multipart/form-data'
    upload<T = any>(
        url: string,
        file: File,
        config?: AxiosRequestConfig & RequestConfig
    ): Promise<T> {
        const formdata = new FormData();
        formdata.append('file', file, file.name);
        return request({
            url,
            data: formdata,
            ...config,
            method: 'POST',
            headers: {}
        });
    }
};

export default http;
