import { RouteRecordRaw } from 'vue-router';
import { LoginVerificationType } from '../model';
import { RouteKey } from '../routeKey';

export const UserRoutes: Array<RouteRecordRaw> = [
    {
        path: '/user',
        component: () => import('@/layout/index.vue'),
        redirect: '/index',
        // 需要layout的页面
        children: [
            {
                path: 'update',
                name: RouteKey.UpdateUserInfo,
                meta: {
                    title: '修改用户信息',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/user/editUserInfo/index.vue')
            },
            {
                path: 'info',
                name: RouteKey.UserInfo,
                meta: {
                    title: '用户信息',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/user/userInfo/index.vue')
            }
        ]
    }
];
