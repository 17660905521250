import http, { ResultModel } from '@/utils/http';
import {
    AuthChangeEmailParamModel,
    AuthChangePasswordParamModel,
    AuthChangePhoneParamModel,
    AuthEmailPasswordLoginParamModel,
    AuthEmailVerficationLoginParamModel,
    AuthPhonePasswordLoginParamModel,
    AuthPhoneVerficationLoginParamModel,
    LineAction,
    WechatAction,
    LoginResultModel
} from './model';

const Address = {
    // 登录
    Login: '/user/login',
    // 获取用户权限
    GetUserPermission: '/recruit-permission',
    // 修改手机号
    changePhone: '/user/change/phone',
    // 修改邮箱
    changeEmail: '/user/change/email',
    // 修改密码
    changePassword: '/user/password/reset',
    // 同步token到子系统
    syncToken: '/user/token/sync',
    // 绑定line
    bindLine: '/user/bind/line',
    // 解绑
    unbindLine: '/user/unbind/line',
    // // 绑定微信，
    bindWechat: '/user/bind/wechat',
    // 解绑微信
    unbindWechat: '/user/unbind/wechat'
};

// 登录
export async function Login(
    param:
        | AuthPhoneVerficationLoginParamModel
        | AuthPhonePasswordLoginParamModel
        | AuthEmailVerficationLoginParamModel
        | AuthEmailPasswordLoginParamModel
): Promise<ResultModel<LoginResultModel>> {
    return await http.post<ResultModel<LoginResultModel>>(Address.Login, param);
}

// 修改密码
export async function ChangePassword(param: AuthChangePasswordParamModel) {
    return await http.post(Address.changePassword, param);
}

// 绑定微信
export async function BindWechat(code: string) {
    return await http.post(
        Address.bindWechat,
        { code, type: 'officialAccount' },
        {
            isShowError: false
        }
    );
}

// 解绑微信
export async function UnbindWechat(userId = '', wxOpenId = '') {
    return await http.post(Address.unbindWechat, { userId, wxOpenId });
}

// // 修改手机号
// async ChangePhone(param: AuthChangePhoneParamModel) {
//     try {
//         const result = await request(
//             `${this.Address.changePhone}`,
//             {
//                 ...param,
//             },
//             'POST',
//         );
//         return Promise.resolve(result);
//     } catch (error) {
//         throw error;
//     }
// }

// // 修改邮箱
// async ChangeEmail(param: AuthChangeEmailParamModel) {
//     try {
//         const result = await request(
//             `${this.Address.changeEmail}`,
//             {
//                 ...param,
//             },
//             'POST',
//         );
//         return Promise.resolve(result);
//     } catch (error) {
//         throw error;
//     }
// }

// // 修改密码
// async ChangePassword(param: AuthChangePasswordParamModel) {
//     try {
//         const result = await request(
//             `${this.Address.changePassword}`,
//             {
//                 ...param,
//             },
//             'POST',
//         );
//         return Promise.resolve(result);
//     } catch (error) {
//         throw error;
//     }
// }

//   // 绑定line,code,redirect_uri
//   async BindLine(code: string, redirect_uri: string) {
//     try {
//         const result = await request(
//             `${this.Address.bindLine}`,
//             {
//                 code,
//                 redirect_uri,
//             },
//             'POST',
//         );
//         return Promise.resolve(result);
//     } catch (error) {
//         throw error;
//     }
// }

// // 解除绑定
// async UnbindLine(userId: string, lineOpenId: string) {
//     try {
//         const result = await request(
//             `${this.Address.unbindLine}`,
//             {
//                 userId,
//                 lineOpenId,
//             },
//             'POST',
//         );
//         return Promise.resolve(result);
//     } catch (error) {
//         throw error;
//     }
// }

//  // 绑定微信
//  async BindWechat(code: string) {
//     try {
//         const result = await request(
//             `${this.Address.bindWechat}`,
//             {
//                 code,
//             },
//             'POST',
//         );
//         return Promise.resolve(result);
//     } catch (error) {
//         throw error;
//     }
// }

// // 解除绑定
// async UnbindWechat(userId: string, wxOpenId: string) {
//     try {
//         const result = await request(
//             `${this.Address.unbindWechat}`,
//             {
//                 userId,
//                 wxOpenId,
//             },
//             'POST',
//         );
//         return Promise.resolve(result);
//     } catch (error) {
//         throw error;
//     }
// }
