import { GlobalBaseInfoModel } from '@/api/baseData/model';
import { GetBaseData, GetDicData } from '@/api/index';
import { useLang } from './useLang';
import { DicModel, DicType } from '@/api/dic/model';

/**
 * @func 国际化
 * @desc
 * @param {}
 * @return {}
 */
export function useGlobal() {
    /**
     * @func 获取远程国际化
     * @desc
     * @param {}
     * @return {}
     */
    const getRemoteBaseData = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await GetBaseData();
                // 保存到本地
                localStorage.setItem('global', JSON.stringify(res.data || {}));
                // 没有设置语言的时候
                const local = useLang().getLocal();
                if (!local) {
                    // 设置语言
                    useLang().setLocal(res.data?.lang || 'zh');
                }
                resolve(true);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    /**
     * @func 获取基础数据
     * @desc
     * @param {}
     * @return {}
     */
    const getBaseData = (): GlobalBaseInfoModel => {
        const global = localStorage.getItem('global');
        const globalContent = global ? JSON.parse(global || '{}') : {};
        return globalContent;
    };

    /**
     * @func 获取远程字典
     * @desc
     * @param {}
     * @return {}
     */
    const getRemoteDicData = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await GetDicData();
                // 保存到本地
                localStorage.setItem('dicData', JSON.stringify(res.data || {}));
                resolve(true);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    /**
     * @func 获取字典数据
     * @desc
     * @param {}
     * @return {}
     */
    const getDicData = (): DicModel => {
        const dicData = localStorage.getItem('dicData');
        const dicDataContent = dicData ? JSON.parse(dicData || '{}') : {};
        return dicDataContent;
    };

    /**
     * @func 获取字典文本
     * @desc
     * @param {
     *  value 字典的值
     *  dicType 字典类型
     * }
     * @return {
     *  string
     * }
     */
    const getDicLabel = (value: string, dicType: DicType): string => {
        const dicData = getDicData();
        const dic = dicData[dicType] || [];
        const item = dic.find((item) => item.value === value);
        return item ? item.label : '';
    };

    return { getRemoteBaseData, getBaseData, getRemoteDicData, getDicData, getDicLabel };
}
