import App from './App.vue';
import router from '@/router';
import store from '@/store';
import '@/styles/index.less';
import '@icon-park/vue-next/styles/index.css';
import 'vant/lib/index.css';
import 'amfe-flexible';

const app = createApp(App);

app.use(router);
app.use(store);

app.mount('#app');
