import http, { ResultModel } from '@/utils/http';
import { GlobalBaseInfoModel } from './model';

const Address = {
    // 获取基础数据
    GetGlobalBaseInfo: '/baseInfo/global'
};

export async function GetBaseData() {
    return await http.get<ResultModel<GlobalBaseInfoModel>>(Address.GetGlobalBaseInfo);
}
