export const Company_Route_Key = {
    SwitchCreateCompany: 'switchCreateCompany',
    // 注册公司
    RegisterCompany: 'registerCompany',
    // 我的公司
    MyCompany: 'myCompany',
    // 编辑公司基础信息
    EditCompanyBaseInfo: 'editCompanyBaseInfo',
    // 修改公司图片
    EditCompanyImage: 'editCompanyImage',
    // 公司地址列表
    CompanyAddressList: 'companyAddressList',
    // 编辑公司地址
    EditCompanyAddress: 'editCompanyAddress',
    // 编辑公司介绍
    EditCompanyIntroduce: 'editCompanyIntroduce'
};
export const Login_Route_Key = {
    Login: 'login',
    // 注册
    Register: 'register',
    // 忘记密码
    ForgetPassword: 'forgetPassword'
};
export const Tab_Route_Key = {
    Index: 'index'
};
export const User_Route_Key = {
    // 修改用户信息
    UpdateUserInfo: 'updateUserInfo',
    // 用户信息
    UserInfo: 'userInfo'
};
// 组织葭否
export const Organization_Route_Key = {
    // 组织信息
    OrganizationInfo: 'organizationInfo'
};

// 公司成员
export const Recruiter_Route_Key = {
    // 邀请加入公司
    RecruiterInviter: 'recruiterInviter',
    // 审核加入成员
    RecruiterApply: 'recruiterApply',
    // 成员信息
    RecruiterInfo: 'recruiterInfo',
    // 已离职人员
    RecruiterLeave: 'recruiterLeave',
    // 申请加入
    RecruiterApplyJoin: 'recruiterApplyJoin'
};

// 日程
export const Task_Route_Key = {
    // 日程详情
    TaskDetail: 'taskDetail',
    // 分享的日程
    TaskShareDetail: 'taskShareDetail',
    // 月视图
    TaskMonth: 'taskMonth',
    // 团队视图
    TaskTeam: 'taskTeam'
};

// 设置
export const Setting_Route_Key = {
    // 设置
    SystemSetting: 'systemSetting',
    // 账户设置
    AccountSetting: 'accountSetting',
    // 设置密码
    ResetPassword: 'resetPassword'
};

// 结果页
export const Result_Route_Key = {
    // 没有找到
    Notfound: 'notfound',
    // 没有权限
    NotPermission: 'notPermission'
};

export const RouteKey = {
    ...Company_Route_Key,
    ...Tab_Route_Key,
    ...Login_Route_Key,
    ...User_Route_Key,
    ...Setting_Route_Key,
    ...Organization_Route_Key,
    ...Recruiter_Route_Key,
    ...Task_Route_Key,
    ...Result_Route_Key
};
