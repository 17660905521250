import http, { ResultModel } from '@/utils/http';

import {
    CreateDepartmentChatGroupParam,
    CreateDepartmentParam,
    DeleteDepartmentParam,
    DepartmentItemModel,
    UpdateDepartmentParam
} from './model';
const Address = {
    // 创建部门
    CreateDepartment: '/manager/department/add',

    //获取所有部门
    GetDepartment: '/manager/department/all',

    // 修改部门
    UpdateDepartment: '/manager/department/edit',

    //删除部门
    DeleteDepartment: '/manager/department/delete',

    // 搜索部门
    SearchDepartment: '/manager/department/search',
    // 创建群组
    CreateChatGroup: '/manager/department/createChatGroup'
};

// 获取用户信息
// export async function GetUserInfo(): Promise<ResultModel<UserInfoModel>> {
//     return await http.get<ResultModel<UserInfoModel>>(`${Address.GetUserInfo}`);
// }

// // 修改用户信息
// export async function UpdateUserInfo(
//     param: UpdateUserInfoParamModel
// ): Promise<ResultModel<UserInfoModel>> {
//     return await http.post<ResultModel<UserInfoModel>>(`${Address.UpdateUserInfo}`, param);
// }

// 获取部门
export async function GetDepartment(): Promise<ResultModel<DepartmentItemModel[]>> {
    return await http.post<ResultModel<DepartmentItemModel[]>>(`${Address.GetDepartment}`);
}
