import http, { ResultModel } from '@/utils/http';
import {
    CompanyDetailModel,
    CompanyLibDetailModel,
    CompanyLibKeywordItemModel,
    CreateCompanyParamModel,
    GetCompanyLibKeywordParamModel,
    GetCompanyPositionParamModel,
    UpdateCompanyParamModel
} from './model';
import { PositionItemModel } from '../position/model';

const Address = {
    // 获取company列表
    GetCompanyList: '/manager/company/list',
    // 获取公司详情
    GetCompanyDetail: '/manager/company/detail',
    // 获取公司职位
    GetMyCompanyPosition: '/position/manager/myPublish',
    // 创建Company
    CreateCompany: '/manager/company/create',
    // 修改Company
    UpdateCompany: '/manager/company/update',
    //获取公司信息
    GetMyCompanyInfo: '/manager/company/my',

    // 获取公司库关键词
    GetCompanyLibKeyword: '/company/tax/lib/keyword',
    // 获取公司库详情
    GetCompanyLibDetail: '/company/tax/lib/detail'
};

// 获取用户信息
// export async function GetUserInfo(): Promise<ResultModel<UserInfoModel>> {
//     return await http.get<ResultModel<UserInfoModel>>(`${Address.GetUserInfo}`);
// }

// 获取我的公司GetMyCompanyInfo
export async function GetRemoteMyCompanyInfo(
    isShowError = true
): Promise<ResultModel<CompanyDetailModel>> {
    return await http.get<ResultModel<CompanyDetailModel>>(
        `${Address.GetMyCompanyInfo}`,
        {},
        {
            isShowError
        }
    );
}

// 获取公司职位
export async function GetCompanyPosition(
    param: GetCompanyPositionParamModel
): Promise<ResultModel<PositionItemModel[]>> {
    return await http.post<ResultModel<PositionItemModel[]>>(
        `${Address.GetMyCompanyPosition}`,
        param
    );
}

// 创建公司
export async function CreateCompany(param: CreateCompanyParamModel): Promise<ResultModel<string>> {
    return await http.post<ResultModel<string>>(`${Address.CreateCompany}`, param);
}

// 修改公司信息
export async function UpdateCompany(param: UpdateCompanyParamModel): Promise<ResultModel<string>> {
    return await http.post<ResultModel<string>>(`${Address.UpdateCompany}`, param);
}

// 获取公司库关键词
export async function GetCompanyLibKeyword(
    param: GetCompanyLibKeywordParamModel
): Promise<ResultModel<CompanyLibKeywordItemModel[]>> {
    return await http.post<ResultModel<CompanyLibKeywordItemModel[]>>(
        `${Address.GetCompanyLibKeyword}`,
        {
            keyword: param
        }
    );
}

// 获取公司库详情
export async function GetCompanyLibDetail(id: string): Promise<ResultModel<CompanyLibDetailModel>> {
    return await http.post<ResultModel<CompanyLibDetailModel>>(`${Address.GetCompanyLibDetail}`, {
        id
    });
}
