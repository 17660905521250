import { RouteRecordRaw } from 'vue-router';
import { LoginVerificationType } from '../model';
import { RouteKey } from '../routeKey';

export const LoginRoutes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        redirect: '/index',
        children: [
            {
                path: 'index',
                name: RouteKey.Login,
                meta: {
                    title: '登录',
                    loginVerificationType: LoginVerificationType.MUST_NOT_LOGIN,
                    style: {
                        backgroundColor: '#fff'
                    }
                },
                component: () => import('@/pages/login/login/index.vue')
            },
            //注册
            {
                path: 'register',
                name: RouteKey.Register,
                meta: {
                    title: '注册',
                    loginVerificationType: LoginVerificationType.MUST_NOT_LOGIN,
                    style: {
                        backgroundColor: '#fff'
                    }
                },
                component: () => import('@/pages/login/register/index.vue')
            },
            // 忘记密码
            {
                path: 'forgetPassword',
                name: RouteKey.ForgetPassword,
                meta: {
                    title: '忘记密码',
                    loginVerificationType: LoginVerificationType.MUST_NOT_LOGIN,
                    style: {
                        backgroundColor: '#fff'
                    }
                },
                component: () => import('@/pages/login/forgetPassword/index.vue')
            }
            // 修改密码
            // {
            //     path: 'updatePassword',
            //     name: RouteKey.UpdatePassword,
            //     meta: {
            //         title: '修改密码',
            //         loginVerificationType: LoginVerificationType.MUST_LOGIN,
            //         style: {
            //             backgroundColor: '#fff'
            //         }
            //     },
            //     component: () => import('@/pages/login/updatePassword/index.vue')
            // }
        ]
    }
];
