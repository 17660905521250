import { GetAllProvince, GetCity, GetArea } from '@/api/index';
import { useLang } from './useLang';
import { UpdateUserInfoParamModel, UserInfoModel } from '@/api/user/model';
import { OptionItemModel } from '@/model/common';

/**
 * @func 用户信息
 * @desc
 * @param {}
 * @return {}
 */
export function useCity() {
    /**
     * @func 获取省份
     * @desc
     * @param {}
     * @return {}
     */
    const getRemoteProvince = async (): Promise<OptionItemModel[]> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await GetAllProvince();
                // 保存到本地
                localStorage.setItem('province', JSON.stringify(res.data || {}));
                resolve(res.data);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    /**
     * @func 获取本地省份
     * @desc
     * @param {}
     * @return {}
     */
    const getProvince = (): OptionItemModel[] => {
        const province = localStorage.getItem('province');
        const provinceContent = province ? JSON.parse(province || '[]') : [];
        return provinceContent;
    };

    /**
     * @func 获取城市
     * @desc
     * @param {}
     * @return {}
     */
    const getCity = async (id: string): Promise<OptionItemModel[]> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await GetCity(id);
                resolve(res.data);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    /**
     * @func 获取区域
     * @desc
     * @param {}
     * @return {}
     */
    const getArea = async (id: string): Promise<OptionItemModel[]> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await GetArea(id);
                resolve(res.data);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    return { getRemoteProvince, getProvince, getCity, getArea };
}
