import { RouteRecordRaw } from 'vue-router';
import { LoginVerificationType } from '../model';
import { RouteKey } from '../routeKey';

export const CompanyRoutes: Array<RouteRecordRaw> = [
    {
        path: '/company',
        component: () => import('@/layout/index.vue'),
        redirect: '/index',
        // 需要layout的页面
        children: [
            {
                path: 'switchCreate',
                name: RouteKey.SwitchCreateCompany,
                meta: {
                    title: '选择加入公司的方式',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/company/switchCreateCompany/index.vue')
            },
            {
                path: 'register',
                name: RouteKey.RegisterCompany,
                meta: {
                    title: '注册公司',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/company/registerCompany/index.vue')
            },
            // 我的公司
            {
                path: 'my',
                name: RouteKey.MyCompany,
                meta: {
                    title: '我的公司',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/company/myCompany/index.vue')
            },
            // 编辑公司基础信息
            {
                path: 'editBaseInfo',
                name: RouteKey.EditCompanyBaseInfo,
                meta: {
                    title: '编辑公司基础信息',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/company/editCompanyBaseInfo/index.vue')
            },
            // EditCompanyImage
            {
                path: 'editImage',
                name: RouteKey.EditCompanyImage,
                meta: {
                    title: '修改公司图片',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/company/editCompanyImage/index.vue')
            },
            // 公司地址列表
            {
                path: 'addressList',
                name: RouteKey.CompanyAddressList,
                meta: {
                    title: '公司地址列表',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/company/companyAddressList/index.vue')
            },
            // EditCompanyAddress
            {
                path: 'editAddress',
                name: RouteKey.EditCompanyAddress,
                meta: {
                    title: '编辑公司地址',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/company/editCompanyAddress/index.vue')
            },
            // EditCompanyIntroduce
            {
                path: 'editIntroduce',
                name: RouteKey.EditCompanyIntroduce,
                meta: {
                    title: '编辑公司介绍',
                    loginVerificationType: LoginVerificationType.MUST_LOGIN
                },
                component: () => import('@/pages/company/editCompanyIntroduce/index.vue')
            }
        ]
    }
];
