import http, { ResultModel } from '@/utils/http';

const Address = {
    // 获取用户权限
    GetUserPermission: '/recruit-permission'
};

// 获取用户信息
export async function GetUserPermission(): Promise<ResultModel<string[]>> {
    return await http.get<ResultModel<string[]>>(`${Address.GetUserPermission}`);
}
