import { GetLanguageForCountry } from '@/api/language/index';
import { LangList, LanguageType } from '@/api/language/model';
import { OptionItemModel } from '@/model/common';
import { useLoading, useGlobal } from '@/hooks';
import 'dayjs/locale/zh-cn'; // 导入本地化语言
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

/**
 * @func 国际化
 * @desc
 * @param {}
 * @return {}
 */
export function useLang() {
    // let toast: any = null;

    const l = (keyword: string) => {
        const lang = localStorage.getItem('lang');
        const langContent = lang ? JSON.parse(lang) : {};
        return langContent[keyword] || keyword || '';
    };

    /**
     * @func 获取远程国际化
     * @desc
     * @param {}
     * @return {}
     */
    const getRemoteLang = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await GetLanguageForCountry();
                // 保存到本地
                localStorage.setItem('lang', JSON.stringify(res.data || {}));
                resolve(true);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    /**
     * @func 设置本地语言
     * @desc
     * @param {}
     * @return {}
     */
    const setLocal = (lang: string) => {
        // dayjs设置语言
        localStorage.setItem('local', lang);
    };

    /**
     * @func 修改本地语言，并且刷新界面
     * @desc
     * @param {}
     * @return {}
     */
    const changeLocal = async (lang: string) => {
        try {
            setLocal(lang);
            useLoading().showText(l('正在切换语言'));
            await useLang().getRemoteLang();
            // 刷新页面
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * @func 获取本地语言
     * @desc
     * @param {}
     * @return {}
     */
    const getLocal = () => {
        try {
            const result = localStorage.getItem('local');
            if (!result || result === 'undefined') {
                // 浏览器语言
                let windowLocal = window.navigator.language || window.navigator['userLanguage'];
                if (!LangList.includes(windowLocal)) {
                    // 设置为日语
                    windowLocal = LanguageType.Japanese;
                }
                setLocal(windowLocal);
                return windowLocal.toLocaleLowerCase();
            }
            return result.toLocaleLowerCase();
        } catch (error) {
            return '';
        }
    };

    /**
     * @func 获取可以使用的语言列表
     * @desc
     * @param {}
     * @return {
     *  OptionItemModel[]
     * }
     */
    const getLangList = (): OptionItemModel[] => {
        const baseData = useGlobal().getBaseData();
        return baseData.languageList || [];
    };

    /**
     * @func 获取本地语言
     * @desc
     * @param {}
     * @return {}
     */
    const getLocalStr = () => {
        const local = getLocal();
        const languageList = getLangList();

        const result =
            languageList.find((item) => `${item.value}`.toLocaleLowerCase() === local)?.label || '';
        return result;
    };

    /**
     * @func 加载时间语言包
     * @desc
     * @param {}
     * @return {}
     */
    const loadDayjsLang = async () => {
        const lang = getLocal();
        dayjs.extend(updateLocale);
        console.log(lang);
        if (lang === 'en-us') {
            await import('dayjs/locale/en');
        } else if (lang === 'zh-cn') {
            await import('dayjs/locale/zh-cn');
        } else if (lang === 'vi-vn') {
            // 越南语
            await import('dayjs/locale/vi');
        } else if (lang === 'id-id') {
            // 印尼
            await import('dayjs/locale/id');
        } else if (lang === 'ta-lk') {
            // 印尼
            await import('dayjs/locale/ta');
        } else {
            await import('dayjs/locale/ja');
        }
        dayjs.locale(lang);
        console.log('加载时间语言包', dayjs.locale());
    };

    return {
        l,
        getRemoteLang,
        getLocal,
        setLocal,
        getLangList,
        getLocalStr,
        changeLocal,
        loadDayjsLang
    };
}
