import { GetBaseData, GetDicData, GetUserInfo, UpdateUserInfo } from '@/api/index';
import { useLang } from './useLang';
import { UpdateUserInfoParamModel, UserInfoModel } from '@/api/user/model';

/**
 * @func 用户信息
 * @desc
 * @param {}
 * @return {}
 */
export function useUser() {
    /**
     * @func 获取远程用户信息
     * @desc
     * @param {}
     * @return {}
     */
    const getRemoteUserInfo = async (): Promise<UserInfoModel> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await GetUserInfo();
                // 保存到本地
                localStorage.setItem('userInfo', JSON.stringify(res.data || {}));
                resolve(res.data);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    /**
     * @func 获取用户信息
     * @desc
     * @param {}
     * @return {}
     */
    const getUserInfo = (): UserInfoModel => {
        const userInfo = localStorage.getItem('userInfo');
        const userInfoContent = userInfo ? JSON.parse(userInfo || '{}') : {};
        return userInfoContent;
    };

    /**
     * @func 修改用户信息
     * @desc
     * @param {}
     * @return {}
     */
    const updateUserInfo = (param: UpdateUserInfoParamModel): Promise<UserInfoModel> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await UpdateUserInfo({
                    ...param
                });
                // 保存到本地
                await getRemoteUserInfo();
                resolve(res.data);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    return { getUserInfo, getRemoteUserInfo, updateUserInfo };
}
