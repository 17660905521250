import { RouteRecordRaw } from 'vue-router';
import { LoginVerificationType } from '../model';
import { RouteKey } from '../routeKey';

export const ResultRoutes: Array<RouteRecordRaw> = [
    {
        path: '/result',
        redirect: '/notfound',
        // 需要layout的页面
        children: [
            {
                path: 'notPermission',
                name: RouteKey.NotPermission,
                meta: {
                    title: '没有权限',
                    loginVerificationType: LoginVerificationType.NO_VERIFICATION
                },
                component: () => import('@/pages/setting/system/index.vue')
            },
            {
                path: 'notfound',
                name: RouteKey.Notfound,
                meta: {
                    title: '没有找到页面',
                    loginVerificationType: LoginVerificationType.NO_VERIFICATION
                },
                component: () => import('@/pages/result/notfound/index.vue')
            }
        ]
    }
];
