import type { ConfigProviderThemeVars } from 'vant';

export function useTheme() {
    const themeVars: ConfigProviderThemeVars = {
        dialogWidth: '75vw',
        primaryColor: '#3964E5',
        tabFontSize: '15px',
        cellBackground: 'none',
        cellRightIconColor: '#b9b9b9',
        cellLargeTitleFontSize: '16px',
        collapseItemContentPadding: '0px',
        tagDefaultColor: '#eaeaea',
        tagTextColor: '#484848',
        calendarSelectedDaySize: '30px'
    };

    return { themeVars };
}
